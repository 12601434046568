.categories-tile{
	
	.category-tile-item {
	    margin: 0;
	    padding: 0.5em;
	}
	.category-tile-item-title{
		font-family: KGDrop, Helvetica;
	    font-size: 1.5rem;
	    position: absolute;
	    /* top: 181px; */
	    text-align: center;
	    background: #fff;
	    padding: .5em;
	    border-radius: 4px;
	    font-weight: 700;
	    width: 84%;
	    margin: 0 auto;
	    left: 0;
	    right: 0;
	    text-align: center;
	    /* display: flex; */
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	}
	
	.category-tile-item-content{
		img{
			border-radius:4px;
		}
	}
	
	.category-tile-item-button{
		position:relative;
	}
}

.page-home h2.elementor-heading-title {
	font-family: KGDrop, Helvetica;
    font-size: 2rem;
    text-align: center;
    color: #333;
    font-weight: bold;
	padding: 1em 0;
}

.page-home h1.elementor-heading-title {
	font-family: KGDrop, Helvetica;
    font-size: 2rem;
    text-align: center;
    color: #333;
    font-weight: bold;
    padding: 1em 0;
}


@include media-breakpoint-down(md) {
	.page-home h1.elementor-heading-title {
		font-size: 1.5rem;
	}
	.page-home h2.elementor-heading-title {
		font-size: 1.5rem;
	}
}