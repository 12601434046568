//customization broderies
.product-customization {
	.product-cust-prenom-broder{
		display:none;
	}
}


.promo-flag{
	text-align: center;
	margin: 3% 0;
	.promo-flag-txt{
		font-weight: bold;
		padding: 8px 5%;
		background-color: $brand-primary;
		margin: 0;
		color: #ffff;
		display:inline-block;

		.code{
			background-color: #fff;
			color: $brand-primary;
			padding: 3px 15px;
			margin: 5px;
		}
	}
	.promo-flag-infos{
		font-size: 0.8em;
		font-style: italic;
	}
}


//variants
.product-variants {

  .input-radio {
    &+span {
		strong{
			color:$brand-default;
		}
     
    }
  }
}

.notification-dispo{
	background-color: rgba(255,132,0,0.15);
    border: 1px dashed #FF8400;
    text-align: center;
    padding: 10px;
    color: #FF8400;
}

.product-engagement{
	/*color: #666;*/
    padding: $medium-space;
    background: #F6F7F9;
    font-size: 0.9em;
    
	.product-engagement-row{
		.item-price-decimal{
			font-size:0.9em;
			padding-left: 2px;
		}

		.long{
			color:#E96453;
		}
		
		.carrier-delivery-elem td{
			padding:5px;
		}
		
		 .carrier-delivery-dates{
			color: #004085;
			font-size: 0.9em;
			padding-top: 5px;
		  }
		  
		  .delivery-dates-warning{
			color: #EC5A4F;
			font-size: 0.9em;
			padding-top: 5px;
		  }
		
		.free-shipping-txt{
			color: #004085;
			font-size: 1.1em;
			padding: 10px 0;
		}
	}
	
	/*.product-engagement-row:first-of-type{
		border-bottom: 1px solid hsla(0,0%,63%,.25);
	}*/
}

// Video Youtube dans iqitextendedproducts
@include media-breakpoint-up(md) {
	.video-container-block{
		width: 40%;
    	margin: 20px auto;
	}
}

.video-container-block{
	margin: 20px auto;
}

.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.see-more-container {
  height: 180px;
  overflow: hidden;
  position: relative;
  padding-bottom: 20px;

	.absolute-gradient {
	    background: -webkit-linear-gradient(rgba(255,255,255,0) 0%, #fff 80%);
	    background: linear-gradient(rgba(255,255,255,0) 0%, #fff 80%);
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    z-index: 3;
	}
	
	
	
	.see-more-link {
	    position: absolute;
	    bottom: 10px;
	    width: 100%;
	    text-align: center;
	    z-index: 4;
	}
	
	&.expanded{
		height:auto;
		.absolute-gradient{
			background: none;
		}
	}
}

#content-wrapper{
	.section-title:after{
		width: 100px !important;
	}

}

.category-products{
	.rte-content{
		margin-bottom: 1.5rem;
		a{
			color: $brand-green2 !important;
		}
	}
}
