.page-module-ph-simpleblog-list,
.page-module-ph-simpleblog-single{
	#content-wrapper{
		
		max-width:930px;
		margin:0 auto;
		
		h1{
			color: #2D477A;
			align-items: center;
		    justify-content: center;
		    flex-direction: column;
		}
	}
	
}

.page-module-ph-simpleblog-list{
	.simpleblog-posts{
		.post-item{
			img{
				border-radius:8px;
			}
		}
	}
} 

.page-module-ph-simpleblog-single{
	#content-wrapper{
		.simpleblog__share{
			.section-title{
				text-align:center;
			}
			ul{
				display: flex;
			    align-items: center;
			    justify-content: center;
				li a{
					border-radius: 4px;
				}
			}
			
		}
	}
}

.simpleblog {

  //Post
  &__postInfo{
    ul{
      list-style-type: none;
      padding: 0;
      margin: 0.5rem 0;
      margin-bottom: 2rem;
      display: flex;
	  align-items: center;
	  justify-content: center;

      @media (max-width: 991px) {
        display: block;
        overflow: auto;
      }

      li{
		padding: 3px 0;
        @media (max-width: 991px) {
          float: left;
          width: 50%;
        }

        a {
          color: inherit;
          &:hover {
            text-decoration: underline;
          }
        }

      }
    }
  }

  &__post__content{
	font-size: 1.2rem;
    line-height: 1.3em;
	h2{
		font-size:1.5rem;
	}
  }
}

.simpleblog-posts .post-item {
	  max-width:400px;

}

.simpleblog-posts .post-content {
  margin: 0.8rem 0;
  text-align:justify;
}

