h1.page-title {
	text-align:center;
}

.elementor-widget-heading h2.elementor-heading-title {
	font-weight:normal;
    color:$brand-orange;
	margin: 0;
    line-height: 1.5em;
    padding: 0 0 0.5em 0;
    margin-top: 1.5em;

	a:link:not(.nav-link):not(.btn), a:visited:not(.nav-link):not(.btn) { color:$brand-orange; } 
	a:hover:not(.nav-link):not(.btn) { color:$brand-orange; }
	
    
}
	
@include media-breakpoint-up(sm) {
	.elementor-column-gap-default .elementor-element-populated {
	    padding: 0;
	}
}

.elementor-image{
	margin: 1em 0;
}


.elementor-video-wrapper{
	margin: 2em 0;
}

.elementor-button-wrapper{
	margin: 2em 0;
}

.elementor-products{
	margin: 1.5em 0;
}

.elementor-text-editor p{
	text-align:justify;
}

.lili-collapse-section{
	.collapse{
		margin:0.5rem;
	}	
	.collapsing{
		margin:0.5rem;
	}
}


.lili-collapse-title{
	padding: 1rem;
    cursor: pointer;
	border: 1px solid #d4d4d4;
	margin-top: 1rem;
	
	.fa{
		float:right;
	}
	
	.elementor-heading-title{
		margin:0 !important;
		padding:0;
	}
}

.lili-collapse-title .fa:before {  
  content: "\f068";
}

.lili-collapse-title.collapsed .fa:before {
  content: "\f067";
}

@include media-breakpoint-down(sm) {
	.lili-collapse-title{
		padding: 0.5rem;
	    cursor: pointer;
	}
}

.elementor-brands{
	height:100px;
	img{
		height:98px;
	}
}




