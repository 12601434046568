$font-primary: Open Sans, Helvetica Neue, Arial, Noto Sans, sans-serif;
$font-heading: Open Sans, Helvetica Neue, Arial, Noto Sans, sans-serif;
$font-title: Averia, Helvetica Neue, Arial, Noto Sans, sans-serif;

$brand-default: #4bafa3;
$brand-primary: #2D477A;
$brand-pink: #FC69C3;
$brand-green: #72BE44;
$brand-green2: #51BEB5;
$brand-yellow: #FBB137;
$brand-orange: #FC7837;
$brand-purple: #93A2FF;
$brand-green2: #51BEB5;

$brand-blue: #0295C8;


$txt-primary-color: #333;
$review-star-color: #FFC946;
