div.homeslider-container{
	margin-bottom:10px;
	
	.homeslider_tabs{
		text-align:center;	
		
		li{
			    display: inline-block;
    			float: none;
				margin: 5px;
		} 
		
		
		
		a{
		    text-indent: -9999px;
		    overflow: hidden;
		    -webkit-border-radius: 15px;
		    -moz-border-radius: 15px;
		    border-radius: 15px;
		    background: #ccc;
		    background: rgba(0,0,0, .2);
		    display: inline-block;
		    display: block;
		    -webkit-box-shadow: inset 0 0 2px 0 rgba(0,0,0,.3);
		    -moz-box-shadow: inset 0 0 2px 0 rgba(0,0,0,.3);
		    box-shadow: inset 0 0 2px 0 rgba(0,0,0,.3);
		    width: 10px;
		    height: 10px;
		}
	}
	
	.homeslider_here a{
	    background: #222;
	    background: rgba(0,0,0, .8);
	}
	
	.homeslider_nav{
		background: transparent url(../img/home/homeslider.gif) no-repeat left top;
	}
	
	.homeslider_nav.next {
	    left: auto;
	    background-position: right top;
	    right: 0;
	}
	
	.homeslider_nav.next {
	    left: auto;
	    background-position: right top;
	    right: 0;
	}
}



.homeslider .slide .caption {
    display: block;
    position: absolute;
    z-index: 2;
    font-size: 1.3rem;
    text-shadow: none;
    color: #ED3A1F;
    /* background: #fff; */
    /* background: rgba(0,0,0, .6); */
	background:none;
    
	bottom: 10%; 
    bottom: -webkit-calc(50% - (300px / 2));
    bottom: -moz-calc(50% - (300px / 2));
    bottom: calc(50% - (300px / 2));
    width: 500px;
    min-height: 200px;

    padding: 10px 0;
    margin: 0;
    /* max-width: none; */
    text-align: center;
    /* font-family: KGDrop; */

	h2{
		font-size: 1.4em;
	}
	
	div{
		margin: 0.5em 0 0 0;
	}

	a.btn-primary{
		background: #ed3a1f;
	    border-radius: 8px;
	    min-width: 200px;
	    padding: 0.2em;
	    font-size: 1.2em;
	    font-family: KGDrop, Helvetica;
		margin: 0.5em 0 0 0;
	}
}



.homeslider .slide-prenez-soin-de-vous{
	.caption{


    	margin-left: 5em;
		bottom: 10%; 
	    bottom: -webkit-calc(50% - (150px ));
	    bottom: -moz-calc(50% - (150px));
	    bottom: calc(50% - (150px));
	    width: 600px;
	    min-height: 200px;
		
		h2{
			font-size: 1.7em;
		    font-family: KGDrop;
		    color: #ED3A1F;
		}
	}
}

.homeslider .slide-bel-t-avec-les-amis-monstres{
	h2{
		color:#fff;
	}
	
	p{
		color:#fff;
	}
}

.homeslider .slide-nouvelle-marque-lalaboom{
	.caption{
			left:600px;
	
	    	h2{
				color:#fff;

			}
			
			p{
				color:#fff;

			}
	}
}

.homeslider .slide-no-l-arrive{
	.christmas_days_countdown{
		background-color: #fff;
	    color: #ed3a1f;
	    font-weight: bold;
	    padding: 3px;
	}
	.caption{
			left:400px;
	
	    	h2{
				color:#fff;
				font-size:2.2em;
			}
			
			p{
				color:#fff;
				font-size:1.4em;
			}
	}
}

@include media-breakpoint-down(md) {
	
	
	.homeslider .slide-prenez-soin-de-vous{
		.caption{
	
	
	    	margin-left: 0;
		    bottom: 10%;
		    bottom: 0;
		    width: 500px;
		    min-height: 150px;
		    font-size: 0.8em;
			
			h2{
				font-size: 1.7em;
			    font-family: KGDrop;
			    color: #ED3A1F;
			}
		}
	}
	
	.homeslider .slide-bel-t-avec-les-amis-monstres{
		.caption{
	
	
	    	h2{
				color:#333;
			}
			
			p{
				color:#333;
			}
		}
	}
	
	.homeslider .slide-nouvelle-marque-lalaboom{
		.caption{
		
		
		    	h2{
					color:#333;
				}
				
				p{
					color:#333;
				}
		}
	}
	
	.homeslider .slide-no-l-arrive{
		.caption{
		
		
		    	h2{
					color:#333;
				}
				
				p{
					color:#333;
				}
		}
	}
    
}

/* down : correspond a tout ce qui est en dessous de xs, sm, lg */
/* up : correspond a tout ce qui est en dessus de xs, sm, lg --> Inverse du down */
/* lg = ipad horizontal or PC */
/* md = ipad vertical */
/* sm = iphone horizontal */
/* xs = iphone vertical */

@include media-breakpoint-down(sm) {
	.homeslider .slide .caption{
		position:relative;
		left:auto;
		bottom:auto;
		right:auto;
		top:auto;
		font-size: 1rem;
		width:100%;
		height:auto;
		
		a.btn-primary{
		    min-width: 150px;
		}
	}
		
}

