.cbp-hrmenu > ul,
.cbp-hrmenu .cbp-hrsub-inner {
  margin: 0 auto;
  max-width: 1170px
}

.cbp-hrmenu li .cbp-hrsub-inner {
    
	padding: 0px 1em;
  
	.iqitmegamenu-submenu-container{
		//max-width: 930px !important;
		max-width: 95% !important;
	}
	

}


.cbp-hrmenu li:nth-of-type(1){
		&.cbp-hropen>a, &.cbp-hropen>a:hover, &.cbp-hropen> a:not([href]):not([tabindex]){
		background:	$brand-pink;
		color:#fff;
	}
	
	.cbp-hrsub-inner {
	  border: 2px solid $brand-pink;
	 
		.cbp-column-title{
			color:	$brand-pink !important;
		} 
		.cbp-column-title:link{
			color:	$brand-pink !important;
		} 
	}
	
	
}

.cbp-hrmenu li:nth-of-type(2){
		&.cbp-hropen>a, &.cbp-hropen>a:hover, &.cbp-hropen> a:not([href]):not([tabindex]){
		background:	$brand-green;
		color:#fff;
	}
	
	.cbp-hrsub-inner {
	  border: 2px solid $brand-green;
	  	.cbp-column-title{
			color:	$brand-green !important;
		} 
		.cbp-column-title:link{
			color:	$brand-green !important;
		} 
	}
}

.cbp-hrmenu li:nth-of-type(3){
		&.cbp-hropen>a, &.cbp-hropen>a:hover, &.cbp-hropen> a:not([href]):not([tabindex]){
		background:	$brand-green2;
		color:#fff;
	}
	
	.cbp-hrsub-inner {
	  border: 2px solid $brand-green2;
	  	.cbp-column-title{
			color:	$brand-green2 !important;
		} 
		.cbp-column-title:link{
			color:	$brand-green2 !important;
		} 
	}
} 


.cbp-hrmenu li:nth-of-type(4){
		&.cbp-hropen>a, &.cbp-hropen>a:hover, &.cbp-hropen> a:not([href]):not([tabindex]){
		background:	$brand-orange;
		color:#fff;
	}
	
	.cbp-hrsub-inner {
	  border: 2px solid $brand-orange;
	  .cbp-column-title{
			color:	$brand-orange !important;
		} 
		.cbp-column-title:link{
			color:	$brand-orange !important;
		} 
	}
}

.cbp-hrmenu li:nth-of-type(5){
		&.cbp-hropen>a, &.cbp-hropen>a:hover, &.cbp-hropen> a:not([href]):not([tabindex]){
		background:	$brand-yellow;
		color:#fff;
	}
	
	.cbp-hrsub-inner {
	  border: 2px solid $brand-yellow;
	  .cbp-column-title{
			color:	$brand-yellow !important;
		} 
		.cbp-column-title:link{
			color:	$brand-yellow !important;
		} 
	}
}

.cbp-hrmenu li:nth-of-type(8){
	&.cbp-hropen>a, &.cbp-hropen>a:hover, &.cbp-hropen> a:not([href]):not([tabindex]){
		background:	$brand-blue;
		color:#fff;
	}
	
	.cbp-hrsub-inner {
	  border: 2px solid $brand-blue;
	  .cbp-column-title{
			color:	$brand-blue !important;
		} 
		 .cbp-column-title:link{
			color:	$brand-blue !important;
		} 
	}
}






