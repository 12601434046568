$gray-darker: #414141;
$gray-dark: #878787;
$gray: #acaaa6;
$gray-lighter: rgba(160, 160, 160, 0.25);
$brand-primary: #2fb5d2;
$brand-secondary: #f39d72;
$brand-success: #4cbb6c;
$brand-warning: #ff9a52;
$brand-danger: #ff4c4c;
$blue: #2fb5d2;
$teal: $gray-dark;
$red: $brand-danger;
$label-color: $gray-darker;
$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: transparent;
$btn-secondary-color: $gray-darker;
$btn-secondary-bg: $gray-lighter;
$btn-secondary-border: transparent;
$btn-border-radius: 0;
$headings-font-weight: 700;
$tooltip-bg: $brand-danger;
$border-radius: 0;
$extra-small-space: 0.2rem;
$extra-small-space2: 0.3rem;
$small-space: 0.5rem;
$medium-space: 1rem;
$large-space: 2rem;
$extra-large-space: 3rem;
$warning: #FF9A52;
$warning-hover: #FDE7BB;
$alert-warning-bg: rgba(255,154,82,0.3);
$alert-warning-border: $warning;
$alert-warning-text: $gray-darker;
$btn-warning-bg: $warning;
$btn-warning-color: white;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;
$base-font-size: 1rem;
$font-size-lg: 0.9375rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.8125rem;
$font-size-h1: 1.375rem;
$font-size-h2: 1.25rem;
$font-size-h3: 1.125rem;
$font-size-h4: 1.125rem;
$font-size-h5: 1rem;
$font-size-h6: 0.9375rem;
$display1-size: rem(50px);
$display1-weight: 600;
$display2-size: rem(35px);
$display2-weight: 400;
$display3-size: rem(25px);
$display3-weight: 400;
$display4-size: rem(20px);
$display4-weight: 400;
$text-muted: $gray;
//warehouse
$default-transition: all .2s cubic-bezier(0.7,0,0.3,1);
$smooth-transition-timing: cubic-bezier(0.7,0,0.3,1);
$gray-light: rgba(160, 160, 160, 0.25);
$gray-light-bg: hsla(0, 0%, 80%, 0.15);
